<template>
  <v-card class="elevation-12">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>Login form</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-alert v-if="signInError" type="error"><span v-html="signInError"></span></v-alert>
      <v-form ref="form" v-model="valid">
        <v-text-field
          label="Email address"
          name="email"
          prepend-icon="mdi-account"
          type="text"
          v-model="email"
          :rules="emailRules"
          validate-on-blur
          ref="emailInput"
        ></v-text-field>

        <v-text-field
          id="password"
          label="Password"
          name="password"
          prepend-icon="mdi-lock"
          type="password"
          v-model="password"
          :rules="passwordRules"
          validate-on-blur
          ref="passwordInput"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn text small :href="forgotPasswordURL()">Forgot Password</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" large class="px-6" @click="login">Sign In</v-btn>
    </v-card-actions>
    <v-divider class="mb-2"></v-divider>
    <v-card-text>
      <span class="text-body-1">Or sign in with your existing Google or Facebook account:</span>
      <v-alert v-if="authError" type="error">{{ authError }}</v-alert>
      <v-btn
        block
        x-large
        color="white"
        class="my-4 btn-google text-h6"
        @click="googleAuthorize()"
        ><v-avatar tile size="33" class="mr-2"
          ><v-img src="@/assets/images/g-logo.png"></v-img
        ></v-avatar>
        Sign in with Google</v-btn
      >
      <v-btn
        block
        x-large
        class="my-4 btn-facebook"
        color="#1877f2"
        dark
        @click="facebookSignIn()"
        ><v-icon large class="mr-2">mdi-facebook</v-icon> <span class="fb-text">Log in With
        Facebook</span></v-btn
      >
    </v-card-text>
    <v-divider class="mb-2"></v-divider>
    <v-card-text class='d-flex'>
      <v-btn text small @click="signUp" class="mx-auto">Or register a FeePlus account now</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      email: "",
      password: "",
      valid: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      signInError: '',
      authError: '',
      signingIn: false
    };
  },
  mounted() {
    window.initGoogleOAuth = () => {
      window.gapi.load("auth2"); // for authorize only
      // for Google session
      // window.gapi.load("auth2", function() {
      //   window.gapi.auth2.init({
      //     client_id:
      //       "45496035347-fb5edbtu4l7iirtuugm06ejh7v2mfj0d.apps.googleusercontent.com",
      //   });
      // });
    };
    let oauthScript = document.createElement("script");
    oauthScript.setAttribute(
      "src",
      "https://apis.google.com/js/platform.js?onload=initGoogleOAuth"
    );
    oauthScript.setAttribute("defer", true);
    document.head.appendChild(oauthScript);

    // Facebook - https://developers.facebook.com/docs/javascript/quickstart
    // window.fbAsyncInit = function() {
    //   window.FB.init({
    //     appId: process.env.VUE_APP_FB_APP_ID,
    //     autoLogAppEvents: true,
    //     xfbml: true,
    //     version: "v7.0",
    //   });
    // };
    // let fbScript = document.createElement("script");
    // fbScript.setAttribute("src", "https://connect.facebook.net/en_US/sdk.js");
    // fbScript.setAttribute("defer", true);
    // fbScript.setAttribute("crossorigin", "anonymous");
    // document.head.appendChild(fbScript);

    const div = document.createElement("div");
    div.setAttribute("id", "fb-root");
    document.body.insertBefore(div, document.body.firstChild);
    // <div id="fb-root"></div>

    let fbScript = document.createElement("script");
    fbScript.setAttribute(
      "src",
      "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v7.0&appId=" +
        process.env.VUE_APP_FB_APP_ID +
        "&autoLogAppEvents=1"
    );
    fbScript.setAttribute("defer", true);
    fbScript.setAttribute("crossorigin", "anonymous");
    fbScript.setAttribute("nounce", "UifhJSPa");
    document.head.appendChild(fbScript);
  },
  methods: {
    async signedIn() { // to be called when user is logged in
      this.signingIn = true;
      this.$emit('success');
      await this.$store.dispatch("fetchUserData")
      await this.$store.dispatch("fetchCustomer")
    },
    async login() {
      this.$refs.form.validate();
      if (this.valid) {
        let res = await this.$store.dispatch({
          type: "login",
          email: this.email,
          password: this.password,
        });
        if (res.error == false) {
          this.signedIn()
        } else {
          this.signInError = res.message
          // Swal.fire({
          //   icon: "error",
          //   text: res.message,
          // });
          this.password = "";
        }
      }
    },
    async checkGoogleResponse(r) {
      // console.log(r);
      if (r.error) {
        if(!this.signingIn) this.authError = r.error + ': ' + r.error_subtype;
        // console.log("authorizeResponse: An error occurred", r.error);
        return;
      } else {
        // console.log("authorizeResponse", r);
        let res = await this.$store.dispatch({
          type: "login",
          provider: "google",
          code: r.code,
        });
        if (res.error == false) {
          this.signedIn()
        } else {
          this.authError = res.message
          Swal.fire({
            icon: "error",
            text: res.message,
          });
        }
      }
    },
    googleAuthorize() {
      // let vue = this;
      window.onmessage = e => {
        if(typeof e.data == 'object' && 'type' in e.data && e.data.type == 'google'){
          this.checkGoogleResponse(e.data.response);
        }
      };
      let winFocus = async () => {
        // check if signed in - 20210907
        await this.$store.dispatch('fetchUser')
        if(this.$store.state.fp_user.id){
          window.removeEventListener('focus', winFocus, true); // remove when it is called for the first time
          this.signedIn()
        }
      }
      window.addEventListener('focus', winFocus, true);
      window.open(process.env.VUE_APP_FEEPLUS_URL+'/signin/google','_blank');
      return;
      //let vue = this;
      // window.gapi.auth2.authorize(
      //   {
      //     client_id:
      //       "45496035347-fb5edbtu4l7iirtuugm06ejh7v2mfj0d.apps.googleusercontent.com",
      //     scope: "email profile openid",
      //     response_type: "id_token permission",
      //     cookiepolicy: 'none',
      //     ux_mode: "popup",
      //     // ux_mode: "redirect",
      //     redirect_uri: process.env.VUE_APP_FEEPLUS_URL+"/app/google.html"
      //   },
      //   this.checkGoogleResponse
      //   // async (r) => {
      //     // if (r.error) {
      //     //   this.authError = r.error + ': ' + r.error_subtype;
      //     //   console.log("authorizeResponse: An error occurred", r.error);
      //     //   return;
      //     // } else {
      //     //   console.log("authorizeResponse", r);
      //     //   let res = await vue.$store.dispatch({
      //     //     type: "login",
      //     //     provider: "google",
      //     //     id_token: r.id_token,
      //     //   });
      //     //   if (res.error == false) {
      //     //     vue.signedIn()
      //     //   } else {
      //     //     this.authError = res.message
      //     //     Swal.fire({
      //     //       icon: "error",
      //     //       text: res.message,
      //     //     });
      //     //   }
      //     // }
      //   // }
      // );
    },
    // checkGoogleSignIn() {
    //   if (window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
    //     let GoogleUser = window.gapi.auth2.getAuthInstance().currentUser.get();
    //     console.log("GoogleUser.getId()", GoogleUser.getId());
    //     console.log(
    //       "GoogleUser.getAuthResponse().id_token",
    //       GoogleUser.getAuthResponse().id_token
    //     );
    //     let BasicProfile = GoogleUser.getBasicProfile();
    //     console.log("id", BasicProfile.getId());
    //     console.log("name", BasicProfile.getName());
    //     console.log("givenname", BasicProfile.getGivenName());
    //     console.log("familyname", BasicProfile.getFamilyName());
    //     console.log("imageurl", BasicProfile.getImageUrl());
    //     console.log("email", BasicProfile.getEmail());
    //   } else {
    //     console.log("Google not signed in");
    //   }
    // },
    // googleSignOut() {
    //   window.gapi.auth2
    //     .getAuthInstance()
    //     .signOut()
    //     .then((r) => console.log("successfully signed out", r));
    // },
    // googleSignIn() {
    //   // console.log(window.gapi.auth2.getAuthInstance().isSignedIn.get())
    //   window.gapi.auth2
    //     .getAuthInstance()
    //     .signIn({})
    //     .then(() => {
    //       // NOTE: When user signed in, GoogleAuth.isSignedIn.get() is true
    //     })
    //     .catch((e) => {
    //       switch (e.error) {
    //         case "popup_closed_by_user":
    //           break;
    //         case "access_denied":
    //           break;
    //         case "immediate_failed":
    //           break;
    //       }
    //     });
    // },
    facebookSignIn() {
      let vue = this;
      // https://developers.facebook.com/docs/facebook-login/web
      window.FB.login(
        async function(response) {
          if (response.status === "connected") {
            var accessToken = response.authResponse.accessToken;
            let res = await vue.$store.dispatch({
              type: "login",
              provider: "facebook",
              accessToken: accessToken,
            });
            if (res.error == false) {
              vue.signedIn()
            } else {
              if(res.message == 'no email'){
                res.title = 'Failed to log in with your Facebook account.'
                res.message = res.name+', your Facebook account does not has email address. FeePlus account sign in require email address to proceed. Please sign in with other account or register a new FeePlus account.'
              } else {
                res.title = ''
              }
              this.authError = res.message
              Swal.fire({
                icon: "error",
                title: res.title,
                text: res.message,
              });
            }
          }
        },
        { scope: "public_profile,email" }
      );
      // https://developers.facebook.com/docs/facebook-login/access-tokens/
      // window.FB.getLoginStatus(async function(response) {
      //   if (response.status === "connected") {
      //     var accessToken = response.authResponse.accessToken;
      //     console.log("accessToken", accessToken);
      //     let res = await this.$store.dispatch({
      //       type: "login",
      //       provider: "facebook",
      //       accessToken: accessToken,
      //     });
      //     if (res.error == false) {
      //       this.$store.dispatch("fetchUserData");
      //     } else {
      //       Swal.fire({
      //         icon: "error",
      //         text: res.message,
      //       });
      //     }
      //   }
      // });
      // FB.login(
      //   function(response) {
      //     // handle the response
      //     if (response.status === "connected") {
      //       // Logged into your webpage and Facebook.
      //     } else {
      //       // The person is not logged into your webpage or we are unable to tell.
      //     }
      //   },
      //   { scope: "public_profile,email" }
      // );
      // logout
      // FB.logout(function(response) {
      //   // Person is now logged out
      // });
    },
    signUp() {
      // v1
      // this.$emit('success')
      // setTimeout(() => 
      //   this.$router.push({
      //    name: 'CreateAccount'
      // }), 100) // to wait for dialog box trigger history.go(-1)
      // v2 - with callback
      this.$emit('success', ()=>this.$router.push({
         name: 'CreateAccount'
      }))
    },
    forgotPasswordURL(){
      return process.env.VUE_APP_FEEPLUS_URL + '/forgotpassword?next_url=' + encodeURI(window.location.href)
    }
  },
};
</script>
<style scoped>
.btn-google {
  font-family: "Roboto", sans-serif;
  text-transform: inherit;
  color: dimgrey;
}
.btn-facebook {
  text-transform: inherit;
  font-size: 1.25em;
}
</style>
<style lang="sass" scoped>
  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'xs-only')}
    .fb-text
      font-size: 0.9em
</style>